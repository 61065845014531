import { AppShell, Box } from '@mantine/core'
import { useTranslations } from 'next-intl'
import PropTypes from 'prop-types'
import Hero from '../hero.js'
import Footer from './footer.js'
import Navbar from './navbar'

export default function Layout({
  children,
  withAutocomplete = false,
  showSearchBar = true,
  showHero,
  heroSubtitle,
  pageName
}) {
  const t = useTranslations(pageName)
  return (
    <Box>
      <AppShell
        padding={0}
        navbar={
          <Navbar
            withAutocomplete={withAutocomplete}
            showSearchBar={showSearchBar}
          />
        }
        footer={<Footer />}
      >
        {showHero && (
          <Hero
            title={t('heroTitle')}
            subtitle={heroSubtitle || t('heroSubtitle')}
          />
        )}
        {children}
      </AppShell>
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  withAutocomplete: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  showHero: PropTypes.bool,
  heroTitle: PropTypes.string,
  heroSubtitle: PropTypes.node
}
