import Image from 'next/image'
import { useEffect, useState } from 'react'
import { Box } from '@mantine/core'

const ImageFallback = ({ src, fallbackSrc, width, height, style, ...rest }) => {
  const [imgSrc, setImgSrc] = useState(src)
  const [isFallback, setIsFallback] = useState(false)

  useEffect(() => {
    setImgSrc(src)
    setIsFallback(false)
  }, [src])

  const handleError = () => {
    setImgSrc(fallbackSrc)
    setIsFallback(true)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: width || '100%',
        height: height || '100%',
        position: 'relative',
        ...style
      }}
    >
      <Image
        {...rest}
        src={imgSrc}
        width={isFallback ? width || 30 : undefined}
        height={isFallback ? height || 30 : undefined}
        fill={!isFallback}
        onError={handleError}
        style={{
          objectFit: 'cover',
          borderRadius: '4px',
          ...style
        }}
        alt="image"
      />
    </Box>
  )
}

export default ImageFallback
