import { MediaQuery, Stack, Text, Title } from '@mantine/core'
import Image from 'next/image'
import React from 'react'
import styles from '../styles/Home.module.css'

const Hero = ({ title, subtitle }) => {
  return (
    <div className={styles.backgroundContainer}>
      <div className={styles.backgroundBlur}>
        <Image
          src="/backdrop_home.png"
          alt="Background"
          fill
          style={{
            objectFit: 'cover',
            filter: 'blur(100px) brightness(1.7) saturate(2)'
          }}
          quality={60}
          priority
        />
      </div>

      <div className={styles.backgroundOverlay}></div>
      <div className={styles.overlay}></div>

      <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
        <Stack
          sx={{
            position: 'relative',
            height: '190px',
            zIndex: 2
          }}
          align="center"
          justify="center"
        >
          <Stack w="100%" maxw={800} spacing={20} align="center">
            <Title size={40} mt={30} align="center" className={styles.hero}>
              {title}
            </Title>
            <Text
              size="sm"
              align="center"
              sx={{
                zIndex: 3,
                textShadow: 'rgba(255,255,255,0.5) 0px 0px 10px'
              }}
            >
              {subtitle}
            </Text>
          </Stack>
        </Stack>
      </MediaQuery>

      <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
        <Stack
          sx={{
            position: 'relative',
            height: '250px',
            padding: '0 20px',
            zIndex: 2
          }}
          align="center"
          justify="center"
        >
          <Stack align="center" w="100%">
            <Title size={36} mt={10} align="center" className={styles.hero}>
              {title}
            </Title>
          </Stack>
        </Stack>
      </MediaQuery>
    </div>
  )
}

export default Hero
