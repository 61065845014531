import { Box, Button, Card, Flex, Group, Stack, Text } from '@mantine/core'
import { useTranslations } from 'next-intl'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'
import TitleDivider from '../components/divider'
import ImageFallback from '../components/image_fallback'
import Layout from '../components/layout'
import platformData from '../pages/data/top_country_hits.json'
import styles from '../styles/Home.module.css'
import { searchAPI_v2 } from '../utils/axios'

export default function Home({ title, platformHits }) {
  const { locale } = useRouter()
  const countryCode = locale.split('-')[1]?.toLowerCase()

  const t = useTranslations('Index')
  const t_genres = useTranslations('Genres')

  const generateContentString = (contentType, genre, year) => {
    let contentString = contentType
    if (genre) contentString += ` | ${genre}`
    if (year) contentString += ` | ${year}`
    return contentString
  }

  return (
    <>
      <Head>
        <title>{title || t('pageTitle')}</title>
        <meta name="description" content={t('pageDescription')} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content={title || t('pageTitle')} />
        <meta property="og:description" content={t('pageDescription')} />
        <meta
          property="og:url"
          content={`https://www.streamdiscover.com/${locale}`}
        />
        <meta property="og:image" content="/path/to/your/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title || t('pageTitle')} />
        <meta name="twitter:description" content={t('pageDescription')} />
        <meta name="twitter:image" content="/path/to/your/image.jpg" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main
        className={styles.main}
        style={{
          width: '80vw',
          maxWidth: '1366px',
          margin: '0 auto'
        }}
      >
        <Stack
          w="100%"
          spacing={0}
          align="start"
          mt={10}
          sx={(theme) => ({
            width: '80vw',
            maxWidth: '100%',
            [theme.fn.smallerThan('md')]: { width: '85vw' },
            [theme.fn.smallerThan('sm')]: { width: '90vw' }
          })}
        >
          <TitleDivider titleText={t('trendingCarouselTitle')} />

          <Group
            position="apart"
            w="100%"
            my={24}
            spacing={0}
            sx={(theme) => ({
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              gap: '16px',
              [theme.fn.smallerThan('lg')]: {
                justifyContent: 'center'
              }
            })}
          >
            {Object.keys(platformHits)
              // Filtrar las plataformas que tienen contenido en data
              .filter((key) => platformHits[key]?.data?.length > 0)
              .slice(0, 8)
              .map((key) => (
                <Box
                  key={key}
                  sx={(theme) => ({
                    flex: '0 0 calc(25% - 16px)',
                    maxWidth: 'calc(25% - 16px)',
                    marginBottom: '32px',
                    [theme.fn.smallerThan('md')]: {
                      flex: '0 0 calc(50% - 16px)',
                      maxWidth: 'calc(50% - 16px)'
                    },
                    [theme.fn.smallerThan('sm')]: {
                      flex: '0 0 100%',
                      maxWidth: '100%'
                    }
                  })}
                >
                  <Card
                    sx={{
                      overflow: 'visible',
                      maxWidth: '100%'
                    }}
                    p={0}
                  >
                    <Card.Section
                      sx={{
                        width: '100%',
                        margin: 0,
                        padding: 0,
                        height: '170px',
                        position: 'relative'
                      }}
                    >
                      <ImageFallback
                        src={`https://wsrv.nl/?url=${platformHits[key].data[0].backdrop}&w=600&output=webp&ll`}
                        fallbackSrc="/noimage.png"
                        style={{
                          objectFit: 'cover',
                          borderRadius: '4px'
                        }}
                        alt="movie poster"
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          boxShadow: '0px -3px 0px 0px rgba(123,57,182,1) inset',
                          borderRadius: '4px'
                        }}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: '5px',
                            justifyContent: 'flex-end',
                            textAlign: 'right'
                          }}
                        >
                          <Text
                            color="white"
                            tt="uppercase"
                            fw={700}
                            size={12}
                            sx={{
                              display: 'block'
                            }}
                          >
                            {platformHits[key].data[0].title.length > 30
                              ? platformHits[key].data[0].title.substring(0, 30) +
                                '...'
                              : platformHits[key].data[0].title}
                          </Text>
                          <Text
                            color="white"
                            tt="uppercase"
                            fw={500}
                            size={10}
                            sx={{
                              display: 'block',
                              textAlign: 'right'
                            }}
                          >
                            {generateContentString(
                              t(platformHits[key].data[0].content_type),
                              t_genres(platformHits[key].data[0].genre),
                              platformHits[key].data[0].year
                            )}
                          </Text>
                        </Box>
                        {platformHits[key].data[0].type === 'Series' && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 10,
                              padding: '5px',
                              backgroundColor: 'rgba(123,57,182,1)',
                              borderRadius: '0 0 5px 5px',
                              display: 'flex',
                              placeContent: 'center'
                            }}
                            w={21}
                            h={19}
                          >
                            <Text color="white" size={10} fw={700} p={0}>
                              TV
                            </Text>
                          </Box>
                        )}
                        <Box
                          sx={{
                            position: 'absolute',
                            bottom: -30,
                            right: 0,
                            padding: '5px'
                          }}
                        >
                          <div
                            style={{
                              width: key === 'disney' ? 58 : 63,
                              height: key === 'disney' ? 58 : 63,
                              borderRadius: '8px',
                              overflow: 'hidden'
                            }}
                          >
                            <ImageFallback
                              src={`https://wsrv.nl/?url=${platformHits[key].logo}&w=200&output=webp&ll`}
                              fallbackSrc="/noimage.png"
                              width={key === 'disney' ? 58 : 63}
                              height={key === 'disney' ? 58 : 63}
                              alt="logo"
                              style={{
                                objectFit: 'cover'
                              }}
                            />
                          </div>
                        </Box>
                      </Box>
                    </Card.Section>
                    <Stack
                      p="md"
                      sx={{
                        maxWidth: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                    >
                      {platformHits[key].data.slice(0, 5).map((hit, index) => {
                        const title = hit.title
                        return (
                          <Link
                            href={`/${hit.content_type.toLowerCase()}/${
                              hit.slug || hit.uid
                            }`}
                            passHref
                            key={hit.uid}
                            prefetch={false}
                          >
                            <Flex align="center" sx={{ cursor: 'pointer' }}>
                              <Group
                                spacing={0}
                                sx={{ minWidth: '30%' }}
                                align="baseline"
                              >
                                <Text
                                  fw={800}
                                  color="rgba(123,57,182,1)"
                                  size="35px"
                                  sx={{
                                    lineHeight: 0.9,
                                    marginLeft: index === 0 ? '4px' : '0'
                                  }}
                                >
                                  {index + 1}
                                </Text>
                                <ImageFallback
                                  src={`https://wsrv.nl/?url=${hit.poster}&w=140&output=webp&ll`}
                                  fallbackSrc="/noimage.png"
                                  width={45}
                                  height={67}
                                  alt="poster"
                                  style={{ borderRadius: '4px' }}
                                />
                              </Group>
                              <Stack spacing={0}>
                                <Text
                                  color="white"
                                  tt="uppercase"
                                  fw={400}
                                  size={14}
                                  sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    maxWidth: '180px'
                                  }}
                                >
                                  {title}
                                </Text>
                                <Text
                                  color="#7B39B6"
                                  tt="uppercase"
                                  fw="bolder"
                                  size={10}
                                  sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    maxWidth: '180px'
                                  }}
                                >
                                  {generateContentString(
                                    t(hit.content_type),
                                    t_genres(hit.genre),
                                    hit.year
                                  )}
                                </Text>
                              </Stack>
                            </Flex>
                          </Link>
                        )
                      })}
                      <Button
                        mt="xs"
                        size="xs"
                        fullWidth
                        component={Link}
                        href={`/${locale}/hits/${countryCode}/${
                          platformHits[key].id
                        }-${key.replace(/\s+/g, '').toLowerCase()}`}
                        sx={{
                          backgroundColor: '#7B39B6',
                          color: 'white',
                          fontSize: '10px',
                          fontWeight: 'bold',
                          '&:hover': {
                            backgroundColor: '#6a2ca6'
                          }
                        }}
                      >
                        {t('seeMore')} {key.toUpperCase()}
                      </Button>
                    </Stack>
                  </Card>
                </Box>
              ))}
          </Group>
        </Stack>
      </main>
    </>
  )
}

Home.propTypes = {
  title: PropTypes.string,
  platformHits: PropTypes.object
}

Home.getLayout = function getLayout(page) {
  return (
    <Layout showSearchBar withAutocomplete showHero={true} pageName="Index">
      {page}
    </Layout>
  )
}

export async function getStaticProps({ locale }) {
  const countryCode = locale.split('-')[1]?.toLowerCase()
  const language = locale.split('-')[0]?.toLowerCase()

  const fetchPlatformHits = async (params) => {
    const { data: moviesData } = await searchAPI_v2.get('content/hits', {
      params: { ...params, type: 'movie' }
    })
    const { data: seriesData } = await searchAPI_v2.get('content/hits', {
      params: { ...params, type: 'series' }
    })
    const parsedMovies = moviesData.data.map((movie) => ({
      title: movie.translation?.title || movie.title,
      poster: movie.poster,
      backdrop: movie.backdrop,
      genre: movie.genres[0] || '',
      year: movie.year,
      uid: movie.uid,
      slug: movie.slug,
      content_type: movie.content_type,
      hits: movie.hits
    }))
    const parsedSeries = seriesData.data.map((series) => ({
      title: series.translation?.title || series.title,
      poster: series.poster,
      backdrop: series.backdrop,
      genre: series.genres[0] || '',
      year: series.year,
      uid: series.uid,
      slug: series.slug,
      content_type: series.content_type,
      hits: series.hits
    }))
    return [...parsedMovies, ...parsedSeries].sort((a, b) => b.hits - a.hits)
  }

  const countryPlatforms = platformData[countryCode?.toLowerCase()] || []

  const platformParams = countryPlatforms.map((platform) => ({
    country: countryCode,
    type: ['movie', 'series'],
    limit: 10,
    platform: platform.id,
    logo: platform.logo,
    language: language
  }))

  try {
    const results = await Promise.all(
      platformParams.map((params) => fetchPlatformHits(params))
    )

    const hits = countryPlatforms.reduce((acc, platform, index) => {
      acc[platform.platform] = {
        id: platform.id, // Aquí añadimos el id de la plataforma
        data: results?.[index] || [],
        logo: platformParams[index].logo
      }
      return acc
    }, {})

    const translation = (
      await import(`../locales/${locale.split('-')[0]}/common.json`)
    ).default

    const messages = {
      Genres: translation.Genres,
      Index: translation.Index,
      Navbar: translation.Navbar,
      Footer: translation.Footer,
      Cookies: translation.Cookies
    }

    return {
      props: {
        platformHits: hits,
        countryPlatforms,
        messages
      },
      revalidate: 60 * 60 * 24 * 10
    }
  } catch (error) {
    console.error('Error al cargar los datos:', error)
    return {
      props: {
        error: 'No se pudieron cargar los datos',
        countryPlatforms,
        title: 'Stream Discover'
      },
      revalidate: 60 * 60 * 24 * 10
    }
  }
}
